import { Component } from "@angular/core";
import { ReactFacade } from "../../../facades/react.facade";
import { AccountsService } from "../../../services/accounts.service";
import { filter, switchMap, take } from "rxjs/operators";
import { of } from "rxjs";
import { NavigationEnd } from "@angular/router";
import { Router } from "@angular/router";

declare global {
  interface Window {
    storydocEnv: string;
  }
}

@Component({
  selector: 'ngx-header-notifications-center',
  template: '<div *ngIf="isCollaborationBeta" [id]="id" style="width: 32px;"><div style="width: 32px; height: 32px; display:flex; justify-content:center; align-items: center; cursor:not-allowed; margin-right: 0; margin-left: 8px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M14.6663 13.333H1.33301V11.9997H1.99967V7.35394C1.99967 4.02866 4.68597 1.33301 7.99967 1.33301C11.3134 1.33301 13.9997 4.02866 13.9997 7.35394V11.9997H14.6663V13.333ZM3.33301 11.9997H12.6663V7.35394C12.6663 4.76505 10.577 2.66634 7.99967 2.66634C5.42235 2.66634 3.33301 4.76505 3.33301 7.35394V11.9997ZM6.33301 13.9997H9.66634C9.66634 14.9201 8.92014 15.6663 7.99967 15.6663C7.07921 15.6663 6.33301 14.9201 6.33301 13.9997Z" fill="#12033E"/></svg></div></div>',
  styles: [``], 
})
  
export class NotificationsCenterComponent {
   
  constructor(
    private reactFacade: ReactFacade,
    private accountsService: AccountsService,
    private router: Router
  ) {}
  
  id = 't5bVlNl4Rq6U6cwYkAnZY';
  isCollaborationBeta = false;

  ngOnInit() {
    this.accountsService.getOrgInfo$().pipe(take(1)).subscribe((orgInfo) => {
      this.isCollaborationBeta = orgInfo.collaborationBeta;
    });
  }

  ngAfterViewInit() {
    this.urlChangeListener();
  }

  handleRendering() {
    this.accountsService.getOrgInfo$().pipe(
      take(1),
      switchMap((orgInfo) => {
        this.isCollaborationBeta = orgInfo.collaborationBeta;
        if (!this.isCollaborationBeta) return of(null);
        return this.reactFacade.initializeApp$();
      })
    ).subscribe(() => {
      if (this.isCollaborationBeta) {
        const event = new CustomEvent('notifications-popover', { detail: { rootId: this.id } });
        document.dispatchEvent(event);
      }
    });
  }

  urlChangeListener() {
    this.handleURLChange({pathname: this.router.url});
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const url = event.url;
      this.handleURLChange({pathname: url});
    });
  }

  handleURLChange({pathname}: {pathname: string}) {
    if (!['/pages/editor', '/pages/home'].some(path => pathname.includes(path))) {
      document.dispatchEvent(new CustomEvent('notifications-popover-reset', { detail: { rootId: this.id } }));
      this.handleRendering();
    }
  }
  
  ngOnDestroy() {
    document.dispatchEvent(new CustomEvent('notifications-popover-reset', { detail: { rootId: this.id } }));
  }
}